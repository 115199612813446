@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&family=Roboto:wght@300;400;700&display=swap');

:root {
  --primary-color: #8a2be2;
  --secondary-color: #4b0082;
  --text-color: #e0e0e0;
  --background-color: #050510;
  --card-background: rgba(26, 26, 46, 0.8);
  --accent-color: #9370db;
  --success-color: #00ff7f;
  --warning-color: #ffd700;
  --danger-color: #ff4500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.nk-dashboard {
  min-height: 100vh;
  padding: 2rem;
  background: 
    radial-gradient(circle at 10% 20%, rgba(138, 43, 226, 0.2) 0%, transparent 20%),
    radial-gradient(circle at 90% 80%, rgba(75, 0, 130, 0.2) 0%, transparent 20%),
    linear-gradient(to bottom, #050510, #0a0a23);
}

.nk-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 3rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.5), 0 0 20px rgba(138, 43, 226, 0.3);
  letter-spacing: 2px;
}

.nk-card {
  background-color: var(--card-background);
  border: 1px solid rgba(138, 43, 226, 0.3);
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 20px rgba(138, 43, 226, 0.2);
  backdrop-filter: blur(10px);
}

.nk-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 10px 20px rgba(138, 43, 226, 0.3), 0 0 30px rgba(138, 43, 226, 0.2);
}

.nk-card-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
  text-shadow: 0 0 5px rgba(138, 43, 226, 0.5);
}

.nk-card-body {
  padding: 1.5rem;
}

.nk-data-label {
  font-weight: bold;
  color: var(--accent-color);
  text-shadow: 0 0 5px rgba(147, 112, 219, 0.5);
}

.nk-button {
  font-family: 'Orbitron', sans-serif;
  background-color: var(--primary-color);
  border: none;
  color: var(--text-color);
  padding: 0.7rem 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.5);
}

.nk-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(138, 43, 226, 0.5), 0 0 20px rgba(138, 43, 226, 0.4);
}

.nk-footer {
  text-align: center;
  padding: 2rem 0;
  font-size: 0.9rem;
  color: var(--text-color);
  opacity: 0.7;
  text-shadow: 0 0 5px rgba(224, 224, 224, 0.5);
}

svg {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(0 0 10px rgba(138, 43, 226, 0.5));
}

.graph {
  background-color: rgba(26, 26, 46, 0.6);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 20px rgba(138, 43, 226, 0.2);
  transition: all 0.3s ease;
}

.graph:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 12px rgba(138, 43, 226, 0.3), 0 0 30px rgba(138, 43, 226, 0.2);
}

.graphs-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

input[type="date"],
input[type="time"],
input[type="range"] {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 1rem;
  background-color: rgba(26, 26, 46, 0.6);
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 5px;
  background: var(--secondary-color);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.7);
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.7);
}

/* Responsive design */
@media (max-width: 768px) {
  .nk-title {
    font-size: 2.5rem;
  }

  .nk-card-title {
    font-size: 1.5rem;
  }

  .graphs-container {
    grid-template-columns: 1fr;
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInFromRight {
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideInFromBottom {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.nk-card {
  animation: fadeIn 0.5s ease-out;
}

.nk-card:nth-child(odd) {
  animation: slideInFromLeft 0.5s ease-out;
}

.nk-card:nth-child(even) {
  animation: slideInFromRight 0.5s ease-out;
}

.graphs-container {
  animation: slideInFromBottom 0.5s ease-out;
}

/* Starry background effect */
.nk-dashboard::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px);
  background-size: 550px 550px, 350px 350px, 250px 250px;
  background-position: 0 0, 40px 60px, 130px 270px;
  animation: twinkle 10s linear infinite;
  z-index: -1;
}

@keyframes twinkle {
  from { transform: translateY(0); }
  to { transform: translateY(-550px); }
}