/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Existing styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom styles for Ajai Bhambi Nakshatra Dashboard */
@layer components {
  .nk-dashboard {
    @apply bg-gray-900 text-white min-h-screen p-6;
    background: url('./space_background.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .nk-title {
    @apply text-4xl font-bold mb-6 text-center text-blue-300;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .nk-card {
    @apply bg-gray-800 p-6 rounded-xl shadow-lg;
  }

  .nk-card-title {
    @apply text-2xl font-semibold mb-4 text-blue-100;
    font-family: 'Roboto', sans-serif;
  }

  .nk-data-label {
    @apply text-xl font-semibold mt-4;
    font-family: 'Roboto', sans-serif;
  }

  .nk-sun-data {
    @apply text-yellow-400;
  }

  .nk-moon-data {
    @apply text-blue-300;
  }

  .nk-button {
    @apply bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out;
  }

  .nk-button:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .nk-slider {
    @apply w-full md:w-64 accent-blue-500;
  }

  .nk-footer {
    @apply text-center text-sm text-gray-500 mt-6;
  }
}

/* Responsive grid layout */
.nk-grid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6;
}

/* Additional utility classes */
@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .rotate-45 {
    transform: rotate(45deg);
  }
}